//import 'jquery';
window.$ = window.jQuery = require("jquery");
window.toastr = require("toastr");
require("semantic-ui/dist/semantic.min.js");

//validation
$('#loginform').form({
    // on: 'blur',
    onSuccess: function(event, fields){
        $('#_submit').addClass('disabled');
        $('.ui.input').addClass('disabled');
        $('#blur-hack').focus();
    },
    fields: {
        _username: {
            identifier  : 'username',
            rules: [
                {
                    type   : 'empty',
                    prompt : 'Please enter your username or e-mail'
                }
            ]
        },
        _password: {
            identifier  : 'password',
            rules: [
                {
                    type   : 'empty',
                    prompt : 'Please enter your password'
                }
            ]
        }
    }
});

function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

$(document).ready(function() {
    $(document).on('click', '.forgot-password-modal-button', function () {
        var $modal = $('#forgot-password-modal');
        var $buttons_block = $('.buttons-block');
        $modal.modal({
            closable: false,
            observeChanges: true,
            allowMultiple: false,
            onApprove: function ($el) {
                $buttons_block.addClass('disabled');
                let email = $modal.find('#email-input').val()
                if (email == '') {
                    toastr.error("Email is required for reset password");
                    $buttons_block.removeClass('disabled');
                    return false;
                }
                if (!validateEmail(email)) {
                    toastr.error("Enter your email correctly in the format: example@example.example");
                    $buttons_block.removeClass('disabled');
                    return false;
                }

                $.ajax({
                    url: '/profile/reset-password/'+email,
                    type: 'POST',
                    dataType: 'json'
                }).done(function (data) {
                    if (data.status == 401) {
                        toastr.error("Access denied");
                    } else {
                        if (data.status === 'success') {
                            if (data.msg !== undefined) {
                                toastr.success(data.msg);
                            }
                        } else if (data.status === 'error') {
                            if (data.msg !== undefined) {
                                toastr.error(data.msg);
                            }
                        }
                    }
                });
            },
            onDeny: function ($el) {
                $buttons_block.removeClass('disabled');
            }
        }).modal('show');
        return false;
    });
});